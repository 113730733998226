import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { colors } from '@united-talent-agency/components';
import { deleteCommunication } from '../../api/communications';
import { SortableColumnHeader, Checkbox } from '@united-talent-agency/components';
import LoadMore from '../../components/LoadMore';
import { saveCall } from '../../data/call-todo';
import { CALL_LIST } from '../../support/cypressTags';
import { useWindowSize, WIDTH_LIMIT } from '../../support/windowSize';
import { searchDeskContacts } from '@united-talent-agency/julius-frontend-store';
import BulkDeleteCommunication from '../../components/BulkDeleteCommunication';
import DeleteCallModal from '../../components/DeleteCallModal';
import BulkUpdateStatus from '../../components/BulkUpdateStatus';
import CallRow from '../../components/CallRow';

const NUMBER_OF_COLUMN = 9;

const Component = ({
  styles,
  communications,
  onEditDone,
  search,
  selectAll,
  selectExcept,
  setSelectAll,
  setSelectExcept,
  desk,
  statuses,
  dispatch,
  editCallTodo,
  fetchCounts,
  totalCounts,
  onLoadMore,
  statusOfLoadingMore,
}) => {
  const { width } = useWindowSize();

  // States
  const [showDialog, setShowDialog] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [allDeskContacts, setAllDeskContacts] = useState([]);

  const { companyName: showCompanyName = false } = useFlags();

  const loadDeskContacts = useCallback(async () => {
    if (!desk?._id) {
      return;
    }

    dispatch(searchDeskContacts(desk._id, '')).then((res) => {
      if (res?.body?.error) {
        console.error(res.body.error);
        setAllDeskContacts([]);
      }
      setAllDeskContacts(res.body ?? []);
    });
  }, [desk, dispatch]);

  useEffect(() => {
    loadDeskContacts();
  }, [loadDeskContacts]);

  /**
   * Add the contacts to the calls
   */
  const communicationsWithContacts = useMemo(
    () =>
      communications.map((call) => ({
        ...call,
        contacts:
          allDeskContacts?.find((person) => person.name === call.recipientName)?.contacts ?? [],
      })),
    [allDeskContacts, communications]
  );

  /**
   * Group calls by status
   */
  const callsByStatus = useMemo(
    () =>
      communicationsWithContacts.reduce((agg, communication) => {
        if (!agg[communication.status]) {
          agg[communication.status] = [];
        }
        agg[communication.status].push(communication);
        return agg;
      }, {}),
    [communicationsWithContacts]
  );

  /**
   * Sorts the list of calls by existing statuses in "statuses" and places the unfound statuses in "statuses" at the end of the list
   */
  const list = useMemo(
    () =>
      Object.entries(callsByStatus || {}).sort((a, b) => {
        const i1 = statuses.findIndex((status) => status.status === a[0]);
        const i2 = statuses.findIndex((status) => status.status === b[0]);

        return (i1 === -1 ? statuses.length : i1) - (i2 === -1 ? statuses.length : i2);
      }),
    [callsByStatus, statuses]
  );

  const hideBulkEdits = desk?.settings?.hideBulkEdits;

  const hideCompany = useMemo(() => {
    const condition = !showCompanyName || width < WIDTH_LIMIT;
    return condition;
  }, [showCompanyName, width]);

  const columnWidths = useMemo(
    () =>
      hideCompany
        ? {
            bulkEdits: `6%`,
            status: `8%`,
            starred: `7%`,
            name: `12%`,
            company: `0%`,
            contact: `17%`,
            date: `14%`,
            notes: `28%`,
            actions: hideBulkEdits ? `14%` : `8%`,
          }
        : {
            bulkEdits: `3%`,
            status: `5%`,
            starred: `2%`,
            name: `11%`,
            company: `25%`,
            contact: `14%`,
            date: `10%`,
            notes: `25%`,
            actions: hideBulkEdits ? `8%` : `5%`,
          },
    [hideBulkEdits, hideCompany]
  );

  const onSelectedChange = useCallback(
    (call) => () => {
      if (selectExcept?.has(call._id)) {
        selectExcept.delete(call._id);
      } else if (selectExcept) {
        selectExcept.add(call._id);
      }
      setSelectExcept(new Set(selectExcept));
    },
    [selectExcept, setSelectExcept]
  );

  if (!statuses) {
    return <div />;
  }

  return (
    <>
      {!hideBulkEdits && (
        <div className={styles.bulkActionContainer}>
          <BulkUpdateStatus
            selectAll={selectAll}
            selectExcept={selectExcept}
            statuses={statuses}
            filterParams={search}
            onUpdateApplied={() => {
              setSelectAll(false);
              setSelectExcept(new Set());
              onEditDone && onEditDone();
            }}
          />
          <span className={styles.pipe} />
          <BulkDeleteCommunication
            selectAll={selectAll}
            selectExcept={selectExcept}
            filterParams={search}
            onUpdateApplied={() => {
              setSelectAll(false);
              setSelectExcept(new Set());
              onEditDone && onEditDone();
            }}
          />
        </div>
      )}
      <table id="callTable" className={styles.table} data-cy={CALL_LIST.CALL_LIST}>
        <thead>
          <tr className={styles.tr}>
            {!hideBulkEdits && (
              <th width={columnWidths.bulkEdits}>
                <Checkbox
                  checked={selectAll}
                  indeterminate={selectExcept?.size}
                  clearedBackground
                  onChange={(e) => {
                    const { checked } = e.currentTarget;
                    setSelectAll(checked);
                    setSelectExcept(new Set());
                  }}
                />
              </th>
            )}
            <th style={{ textAlign: 'center' }} width={columnWidths.status}>
              <SortableColumnHeader text="Status" lightWeight />
            </th>
            <th width={columnWidths.starred} />
            <th width={columnWidths.name} className={styles.nameColumn}>
              <SortableColumnHeader text="Name" lightWeight />
            </th>
            {!hideCompany && (
              <th width={columnWidths.company}>
                <SortableColumnHeader text="Company" lightWeight />
              </th>
            )}
            <th style={{ paddingLeft: 7 }} width={columnWidths.contact}>
              <SortableColumnHeader text="Contact" lightWeight />
            </th>
            <th width={columnWidths.date}>
              <SortableColumnHeader text="Date" lightWeight />
            </th>
            <th width={columnWidths.notes} style={{ paddingLeft: 8 }}>
              <SortableColumnHeader text="Notes" lightWeight />
            </th>
            <th style={{ textAlign: 'end', paddingLeft: 12 }} width={columnWidths.actions}>
              <SortableColumnHeader text="Actions" lightWeight />
            </th>
          </tr>
        </thead>
        {list?.map(([status, calls]) => (
          <tbody key={status} data-status={status} data-cy={CALL_LIST.STATUS_SECTION}>
            {calls?.map((call) => (
              <CallRow
                key={`${status}-${call._id}`}
                hideBulkEdits={desk?.settings?.hideBulkEdits}
                dispatch={dispatch}
                item={call}
                recipient={call?.recipientId}
                editCallTodo={editCallTodo}
                onDeleteItem={(item) => {
                  setShowDialog(true);
                  setToDelete(item);
                }}
                statuses={statuses}
                onSave={(callTodo) =>
                  saveCall(callTodo).then((resp) => {
                    onEditDone && onEditDone();
                    return resp.data;
                  })
                }
                selected={selectAll ? !selectExcept?.has(call._id) : selectExcept?.has(call._id)}
                onSelectedChange={onSelectedChange(call)}
                deskContacts={call?.contacts}
              />
            ))}
            {fetchCounts[status] < totalCounts[status] && (
              <tr key={`loadMore:${status}`} data-cy={CALL_LIST.LOAD_MORE}>
                <td colSpan={NUMBER_OF_COLUMN}>
                  <LoadMore
                    loading={statusOfLoadingMore === status}
                    onClick={() => onLoadMore(status)}
                  />
                </td>
              </tr>
            )}
            <tr data-cy={CALL_LIST.STATUS_DIVIDER} key={`statusDivider:${status}`}>
              <td className={styles.divider} colSpan={NUMBER_OF_COLUMN} />
            </tr>
          </tbody>
        ))}
      </table>
      <DeleteCallModal
        isOpen={showDialog}
        onConfirm={() => {
          deleteCommunication(toDelete?._id).then(() => {
            onEditDone && onEditDone();
          });
          setToDelete(null);
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
      />
    </>
  );
};

const withStyles = styled({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 10,
    tableLayout: 'fixed',
  },
  tr: {
    '> th': {
      color: colors.darkGrey,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 2.5,
      paddingRight: 2.5,
      paddingBottom: 3,
    },
  },
  bulkActionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  pipe: {
    display: 'inline-block',
    width: 1,
    height: 20,
    backgroundColor: colors.disabledGrey,
    margin: '0 10px',
  },
  bulkStatusUpdateItem: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': { backgroundColor: 'rgba(41, 161, 219, 0.15)' },
  },
});

const withState = connect(({ desk = {} }) => ({
  statuses: desk.status,
  desk: desk.current,
}));

const CallList = withStyles(withState(Component));

export default CallList;
