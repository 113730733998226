import React, { useCallback } from 'react';
import { FilterPanel } from '@united-talent-agency/components';
import { FILTERS } from '../../support/cypressTags';

const FilterPills = ({ status, search, navigate }) => {
  const { filter, favorite } = search;

  const options = { pills: { targetIsArray: true } };

  /**
   * Re-maps the data for consumption
   * @returns {
   *  [
   *    {
   *      text: "pills",
   *      items: [ {status: string, text: string, include: bool}]
   *    }
   *  ]
   * }
   */
  const parseGroups = useCallback(() => {
    let pills = [];
    if (filter) {
      pills = status?.reduce((arr, item) => {
        if (filter.includes(item.status)) {
          arr.push({
            text: item.name,
            status: item.status,
            include: true,
          });
        }
        return arr;
      }, []);
    }
    if (favorite == '1') {
      pills.push({ status: '1', text: 'Starred', include: true });
    }
    return [{ items: pills, text: 'pills' }];
  }, [favorite, filter, status]);

  /**
   * Receives data from FilterPanel and uses it to update the
   * active filters.
   * @param {
   *  [
   *    {
   *      text: "pills",
   *      items: *[ {status: string, text: string, include: bool}]
   *    }
   *  ]
   * } pills: remaining filter pills
   */
  const onChange = useCallback(
    (pills) => {
      const filter = pills[0].items.reduce((arr, item) => {
        if (item.text !== 'Starred') {
          arr.push(item.status);
        }
        return arr;
      }, []);

      const updatedSearch = { ...search };

      const favorite = pills[0].items.some(({ include, text }) => include && text === 'Starred');

      if (favorite) {
        updatedSearch.favorite = 1;
      } else {
        updatedSearch.favorite = undefined;
      }

      if (filter.length) {
        updatedSearch.filter = filter.join();
      } else {
        updatedSearch.filter = undefined;
      }

      navigate(updatedSearch);
    },
    [navigate, search]
  );

  return (
    <FilterPanel
      deleteOnly={true}
      filters={options}
      groups={parseGroups()}
      onGroupsChanged={onChange}
      cypressTag={FILTERS.FILTER_PILL}
      all
      legacy
      maxStaticOptions={0}
    />
  );
};

export default FilterPills;
