import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { SearchBar as DefaultSearchBar } from '@united-talent-agency/components';
import { SEARCH } from '../../support/cypressTags';
import { useDebouncedCallback } from 'use-debounce';

const Component = ({ styles, search, navigate }, externalRef) => {
  // Refs
  const ref = useRef();

  useImperativeHandle(externalRef, () => ref.current);

  const debouncedNavigate = useDebouncedCallback((_search) => {
    navigate && navigate(_search);
  }, 500);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (value === search.query) {
        return;
      }

      if (!value?.length) {
        search.query = undefined;
        debouncedNavigate({ ...search });
      } else if (3 <= value.length && value.length <= 50) {
        debouncedNavigate({ ...search, query: value });
      }
    },
    [debouncedNavigate, search]
  );

  const handleClear = useCallback(() => {
    search.query = undefined;
    navigate && navigate({ ...search });
  }, [navigate, search]);

  /**
   * This is mandatory to visually update the input value.
   */
  useEffect(() => {
    ref.current.value = search?.query ?? '';
  }, [search?.query]);

  return (
    <div className={styles.search}>
      <DefaultSearchBar
        inputRef={ref}
        cypressTag={SEARCH.SEARCH_BAR}
        value={search.query}
        onKeyUp={handleChange}
        onClear={handleClear}
      />
    </div>
  );
};

const SearchBar = forwardRef(Component);

export default SearchBar;
