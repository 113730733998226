import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';
import {
  colors,
  Icons,
  Checkbox,
  Button,
  Tooltip,
  ToggleSwitch,
} from '@united-talent-agency/components';
import StatusList from '../StatusList';
import { HEADER } from '../../support/cypressTags';

export const updateStatusOrder = ({ oldIndex, newIndex }, statuses, onChange) => {
  let _statuses = statuses.slice();
  //switch the status item's places in the array
  const movedStatusItem = _statuses.splice(oldIndex, 1);
  _statuses.splice(newIndex, 0, ...movedStatusItem);

  //set the order field to be the index
  for (let i = 0; i < _statuses.length; i++) {
    _statuses[i].order = i;
  }

  onChange(_statuses);
  return _statuses;
};

export const SettingsModal = ({
  isOpen,
  onCancel,
  onSave,
  statuses,
  onChangeStatuses,
  errorState,
  navigate,
  search = {},
  desk,
  onAddStatus,
}) => {
  const [hideFutureCallsChecked, setHideFutureCallsChecked] = useState(
    !!desk?.settings?.hideFutureCalls
  );
  const [hideBulkEditsChecked, setHideBulkEditsChecked] = useState(!!desk?.settings?.hideBulkEdits);
  const [filterParams, setFilterParams] = useState([]);

  useEffect(() => {
    setFilterParams(search.filter ?? []);
  }, [search]);

  useEffect(() => {
    setHideBulkEditsChecked(!!desk?.settings?.hideBulkEdits);
  }, [desk?.settings?.hideBulkEdits]);

  useEffect(() => {
    setHideFutureCallsChecked(!!desk?.settings?.hideFutureCalls);
  }, [desk?.settings?.hideFutureCalls]);

  /** Handles closing the modal
   *
   */
  const onRequestClose = () => {
    onCancel();
    setFilterParams(search.filter ?? []);
  };

  /** Removes a status from the status list
   *
   * @param {Number} index: the index of the status to remove
   **/
  const removeStatus = (index) => {
    let _statuses = statuses.slice();

    setFilterParams(filterParams.filter((status) => status !== _statuses[index].status));

    _statuses.splice(index, 1);
    onChangeStatuses(_statuses);
  };

  /** Updates a status from the status list
   *
   * @param {("color"|"name"|"status"|"type")} fieldType: the field to update on the status object
   * @param {String} updatedValue: the new value
   * @param {Number} index: the index of the status to update
   **/
  const updateStatusField = (fieldType, updatedValue, index) => {
    let _statuses = statuses.slice();
    if (fieldType === 'status') {
      _statuses[index]['update'] = true;

      const ind = filterParams.indexOf(_statuses[index].status);
      if (ind >= 0) {
        const updatedFilterParams = [...filterParams];
        updatedFilterParams[ind] = updatedValue;
        setFilterParams(updatedFilterParams);
      }
    }
    _statuses[index][fieldType] = updatedValue;
    onChangeStatuses(_statuses);
  };

  /** Handles saving changes to the status list
   * Saves changes to mongo, then updates the search params in the querystring
   **/
  const handleSave = () => {
    onSave(
      { statuses, hideFutureCalls: hideFutureCallsChecked, hideBulkEdits: hideBulkEditsChecked },
      () => {
        const { filter, ...rest } = search;
        if (filter) {
          filterParams.length
            ? navigate({ ...search, filter: filterParams })
            : navigate({ ...rest });
        }
      }
    );
  };

  const handleBulkToggleSwitch = () => {
    setHideBulkEditsChecked((previousValue) => !previousValue);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <ButtonContainer data-cy={HEADER.SETTINGS.MODAL_CLOSE}>
          <Icons.XCloseIcon
            inline
            onClick={onRequestClose}
            height={23}
            width={23}
            style={{ cursor: 'pointer', marginRight: -7 }}
          />
        </ButtonContainer>

        <ModalTitle>Settings</ModalTitle>
        <div style={{ display: 'flex', margin: '20px 40px' }}>
          <span>Bulk Changes</span>
          <div style={{ width: '63%' }}></div>
          <ToggleSwitch
            initialToggleState={!hideBulkEditsChecked}
            onChange={handleBulkToggleSwitch}
          />
        </div>
        <hr style={{ margin: '0px 40px' }} />
        <div data-cy={HEADER.SETTINGS.MODAL_HIDE_FUTURE_CALLS} style={{ margin: '20px 47px' }}>
          <Checkbox
            title="Hide future calls"
            checked={hideFutureCallsChecked}
            onChange={() => setHideFutureCallsChecked((oldState) => !oldState)}
            clearedBackground
          />
        </div>
        <p style={{ fontSize: 18, margin: '0 47px -5px', fontWeight: 300 }}>Status</p>
        <p style={{ color: 'grey', marginLeft: 47, fontWeight: 300, fontSize: 12 }}>
          (Drag and Drop to reorder)
        </p>
        <StatusList
          useDragHandle
          distance={5}
          desk={desk}
          // statuses={statuses} TODO - [ONX-170] fix how statuses are piped into the StatusList
          statuses={statuses || []}
          removeStatus={removeStatus}
          updateStatusField={updateStatusField}
          onSortEnd={(indexUpdate) => updateStatusOrder(indexUpdate, statuses, onChangeStatuses)}
          errorState={errorState}
        />

        <AddStatusContainer>
          {(statuses || []).length < 6 ? (
            <AddStatusText onClick={() => onAddStatus()}>
              <span style={{ fontSize: 18 }}>+</span>
              <span style={{ position: 'relative', margin: 'auto 4px' }}>Add Status</span>
            </AddStatusText>
          ) : (
            <Tooltip text="You can only have 6 statuses">
              <DisabledAddStatusText>
                <span style={{ fontSize: 18 }}>+</span>
                <span style={{ position: 'relative', margin: 'auto 4px' }}>Add Status</span>
              </DisabledAddStatusText>
            </Tooltip>
          )}
        </AddStatusContainer>

        <SaveButtonContainer data-cy={HEADER.SETTINGS.MODAL_SAVE}>
          <Button small onClick={handleSave} text="Save" />
        </SaveButtonContainer>
      </div>
    </Modal>
  );
};

const AddStatusContainer = styled.div({
  margin: '5px 48px',
});

const AddStatusText = styled.div({
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontSize: 12,
  color: colors.linkBlue,
  cursor: 'pointer',
  width: 'max-content',
  display: 'flex',
});

const DisabledAddStatusText = styled(AddStatusText)({
  color: colors.disabledGrey,
  cursor: 'default',
});

const ButtonContainer = styled.div({
  position: 'absolute',
  right: 15,
});

const SaveButtonContainer = styled.div({
  margin: '0 38px 12px 0',
  justifyContent: 'flex-end',
  display: 'flex',
  position: 'relative',
});

const ModalTitle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 24,
  fontWeight: 300,
});

const modalStyles = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    background: `${colors.background} none repeat scroll 0% 0%`,
    borderRadius: 2,
    outline: 'currentcolor none medium',
    width: 454,
    overflow: 'unset',
    height: 'min-content',
    padding: '20px 0',
  },
};

export default SettingsModal;
