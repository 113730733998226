import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateSearch } from '../api/search';
import { isEqual } from 'lodash';

const objectToQueryString = (params, arrayProperties) => {
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (arrayProperties?.includes(key) && Array.isArray(value)) {
        params[key] = value?.join(',');
      }
    });
  }

  return new URLSearchParams(params).toString();
};

const queryStringToObject = (queryString, arrayProperties) => {
  const obj = Object.fromEntries(new URLSearchParams(queryString));

  Object.entries(obj).forEach(([key, value]) => {
    if (arrayProperties?.includes(key)) {
      obj[key] = value?.split(/%2C|,/g);
    }
  });

  return obj;
};

const arrayProperties = ['filter'];

export default function useQueryParamsSync({ userStoredFilters, deskId: defaultDeskId }) {
  const history = useHistory();
  const location = useLocation();

  // States
  const [deskId, setDeskId] = useState(defaultDeskId);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState(undefined);
  const [favorite, setFavorite] = useState(undefined);

  const removeEmpties = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        obj[key] = value.filter((item) => !!item?.length);
      }

      if ([null, undefined, ''].includes(value) || (Array.isArray(value) && !value?.length)) {
        delete obj[key];
      }
    });

    return obj;
  };

  const search = useMemo(() => {
    const obj = {
      query,
      deskId,
      favorite,
      filter: filters,
    };

    return removeEmpties(obj);
  }, [deskId, favorite, filters, query]);

  const urlParams = useMemo(
    () => queryStringToObject(location.search, arrayProperties),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );

  const storeString = useMemo(
    () => objectToQueryString(userStoredFilters, arrayProperties),
    [userStoredFilters]
  );

  // Sync URL and stored data
  useEffect(() => {
    const store = queryStringToObject(storeString);

    if (!location?.search?.length) {
      // If there is no parameters in the URL, load the stored data and update URL
      setFilters(store?.filter);
      setDeskId(store?.deskId ?? defaultDeskId);
      setFavorite(store?.favorite);
      setQuery(store?.query);

      history.replace(`?${objectToQueryString(search)}`);
    } else if (!isEqual(urlParams, store)) {
      // In case of different params in the URL, update the state and the stored data

      // Change arrays to be comma-separated strings to match the expected API form
      const urlParamsCopy = { ...urlParams };
      Object.entries(urlParamsCopy).forEach(([key, value]) => {
        if (Array.isArray(value) && arrayProperties.includes(key)) {
          urlParamsCopy[key] = value.join(',');
        }
      });

      updateSearch(urlParamsCopy);
      setFilters(urlParams.filter);
      setDeskId(urlParams.deskId || null);
      setFavorite(urlParams?.favorite);
      setQuery(urlParams?.query);
    } else {
      // If the params are the same, just update the local state
      setFilters(store?.filter);
      setDeskId(store?.deskId);
      setFavorite(store?.favorite);
      setQuery(store?.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Update URL and stored data when the filters change
  const updateFilters = useCallback(
    (newFilters) => {
      const updatedParams = { ...urlParams, ...newFilters };
      setFilters(updatedParams.filters);

      const queryString = objectToQueryString(removeEmpties(updatedParams), arrayProperties);
      history.push(`?${queryString}`);
      updateSearch(updatedParams);
    },
    [history, urlParams]
  );

  /**
   * Update URL and stored data when the deskId change.
   * Note: Here we need to replace the current state with the stored data belonging to the new desk.
   */
  const updateDeskId = useCallback(
    (data) => {
      setDeskId(data.deskId);

      const queryString = objectToQueryString(removeEmpties(data), arrayProperties);
      history.push(`?${queryString}`);
      updateSearch(data);
    },
    [history]
  );

  return {
    search,
    // Actions
    updateFilters,
    updateDeskId,
  };
}
